import { useAuth0 } from "@auth0/auth0-react";
import LoadingScreen from "./pages/LoadingScreen";
import { ContextProvider } from "./utils/context";
import Fallback from "./components/Fallback";
import { ErrorBoundary } from "react-error-boundary";
import Router from "./Router";
import Login from "./pages/Login";
import { useEffect, useState } from "react";
import { Capacitor, PluginListenerHandle } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { useHandleSignOut } from "./hooks/UseHandleSignOut";

export default function App(): JSX.Element {
  const { isLoading, user, getAccessTokenSilently } = useAuth0();
  const { handleSignOut } = useHandleSignOut();
  const [isThereAccessToken, setIsThereAccessToken] = useState(false);

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      let showListenerHandle: PluginListenerHandle;
      let hideListenerHandle: PluginListenerHandle;

      const setKeyboardListeners = async () => {
        showListenerHandle = await Keyboard.addListener('keyboardWillShow', ({ keyboardHeight }) => {
          document.documentElement.style.setProperty('--keyboard-height', `${keyboardHeight}px`);
          const activeElement = document.activeElement as HTMLElement;
          if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
            if (activeElement.getBoundingClientRect().bottom > window.innerHeight - keyboardHeight) {
              requestAnimationFrame(() => {
                activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              });
            }
          }
        });

        hideListenerHandle = await Keyboard.addListener('keyboardWillHide', () => {
          document.documentElement.style.removeProperty('--keyboard-height');
        });
      };

      setKeyboardListeners();

      return () => {
        if (showListenerHandle) showListenerHandle.remove();
        if (hideListenerHandle) hideListenerHandle.remove();
      };
    }
  }, []);

  useEffect(() => {
    const testGettingAccessTokenSilently = async () => {
      try {
        await getAccessTokenSilently();
        setIsThereAccessToken(true);
      } catch (error) {
        if (error && typeof error === 'object' && 'error' in error && typeof error.error === 'string' &&
          ['missing_refresh_token', 'invalid_grant', 'login_required'].includes(error.error)) {
          handleSignOut();
        }
      }
    }

    if (Capacitor.isNativePlatform() && !isLoading && user) {
      testGettingAccessTokenSilently();
    }
  }, [isLoading, user, getAccessTokenSilently, handleSignOut]);

  if (isLoading) return <LoadingScreen reference='1' />;

  if (!user) return <Login />;

  if (Capacitor.isNativePlatform() && !isThereAccessToken) return <LoadingScreen reference='2' />;

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <ContextProvider userId={user?.sub ?? ''}>
        <Router />
      </ ContextProvider>
    </ ErrorBoundary>
  );
}
