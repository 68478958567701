import { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../utils/context";
import { useAPI } from "../hooks/UseAPI";
import { Goal } from "../model/Goal";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import EmptyList from "../components/EmptyList";
import GoalStatus from "../components/GoalStatus";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import { getInteger, getDecimal } from '../utils/number-utils';

interface GoalItemProps {
    goal: Goal
}

function GoalItem(props: GoalItemProps): JSX.Element {
    const goal = props.goal;
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();

    return (
        <div className="py-2 cursor-pointer" onClick={() => navigate(`/goals/${goal.goal_id}`)}>
            <div className='flex space-x-1 justify-between font-bold'>
                <p className="truncate pr-4 self-center">{goal.title}</p>
                <div className="flex min-w-fit self-center">
                    <p className="mx-1 font-bold">{getSign(goal.currency)}</p>
                    <p className="font-bold">{getInteger(goal.amount ?? '')}</p>
                    <p className="font-bold text-[11px]/[16px] ml-[1px]">{getDecimal(goal.amount ?? '')}</p>
                </div>
            </div>
            <div className="flex w-full py-1">
                <div className="flex min-h-full text-sm self-center w-full">{goal.term}</div>
                <GoalStatus status={goal.status ?? ''} />
            </div>
            <p className="secondary">{goal.description}</p>
        </div>
    )
}

export default function Goals(): JSX.Element {
    const { client } = useContext(Context);
    const { listGoals } = useAPI();
    const [loading, setLoading] = useState(true);
    const [goals, setGoals] = useState<Goal[]>([]);
    const navigate = useNavigate();

    const setUp = useCallback(async () => {
        setLoading(true);
        try {
            const results = await listGoals(client.id ?? '');
            setGoals(results);
        } finally {
            setLoading(false);
        }
    }, [client.id, listGoals]);

    useEffect(() => { setUp() }, [setUp]);

    if (loading) return <Loading />

    const goalsToDisplay = goals.filter(goal => goal.status === 'pending' || goal.status === 'done');

    return (
        <main>
            <article className="h-fit pb-2 bg-sf-green-light">
                <div className="flex w-full flex-row justify-between items-center">
                    <h2>Objetivos</h2>
                    <button className="button-aux flex space-x-1 items-center" onClick={() => navigate('/goals/new')}>
                        <p>Nuevo</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-3.5" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                    </button>
                </div>

                <div className="divide-y divide-sf-black border-t border-sf-black">
                    {goalsToDisplay?.length ? goalsToDisplay?.sort((a, b) => {
                        if (a.update_timestamp === undefined || b.update_timestamp === undefined
                        ) return 0;

                        if (a.update_timestamp > b.update_timestamp) return -1;
                        if (a.update_timestamp < b.update_timestamp) return 1;

                        return 0;
                    }).map(goal => <GoalItem key={goal.goal_id} goal={goal} />)
                        : <EmptyList message="Aún no se cargaron objetivos." />}
                </div>
            </article>
        </main>
    )
}