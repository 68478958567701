import { SyntheticEvent, useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../utils/context";
import { useAPI } from "../hooks/UseAPI";
import { defaultClient } from "../model/Client";
import Loading from "../components/Loading";
import { Currency, defaultCurrency } from "../model/Currency";
import { ISO_4217_CURRENCY_CODES } from "../consts/currency-codes";
import Select from "../components/Select";
import OperationDetails from "../components/OperationDetails";
import { useHandleSignOut } from "../hooks/UseHandleSignOut";
import Menu from "../components/Menu";

export default function Profile(): JSX.Element {
    const { client, setClient, setConfirmDialog } = useContext(Context);
    const { getClient, updateClient } = useAPI();
    const { handleSignOut } = useHandleSignOut();
    const [loading, setLoading] = useState(true);
    const [editableClient, setEditableClient] = useState({ ...defaultClient });
    const [newCurrency, setNewCurrency] = useState<Currency>({ ...defaultCurrency });

    const setUp = useCallback(async () => {
        try {
            setLoading(true);
            const results = await getClient(client.id ?? '');
            setEditableClient(results);
        } finally {
            setLoading(false);
        }
    }, [client.id, getClient]);

    useEffect(() => { setUp() }, [setUp]);

    function handleInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setEditableClient(oldEditableClient => ({
            ...oldEditableClient,
            [target.name]: target.value
        }));
    }

    function saveChanges() {
        setLoading(true);
        updateClient(client.id ?? '', { ...defaultClient, ...editableClient }).then(() => {
            setClient(editableClient);
        }).finally(() => {
            setLoading(false);
        });
    }

    if (loading) return <Loading />

    function addCurrency() {
        setEditableClient(oldEditableClient => ({
            ...oldEditableClient,
            currencies: [...(oldEditableClient.currencies ?? []), newCurrency]
        }));
        setNewCurrency({ ...defaultCurrency });
    }

    function removeCurrency(index: number) {
        setEditableClient(oldEditableClient => {
            const currenciesCopy = (oldEditableClient.currencies ?? []).slice();
            currenciesCopy.splice(index, 1);

            return {
                ...oldEditableClient,
                currencies: currenciesCopy
            }
        });
    }

    async function handleDeleteAccount() {
        await updateClient(client.id ?? '', { ...client, status: 'delete_account' });

        return handleSignOut();
    }

    const usedCodes = editableClient.currencies?.map(currency => currency.code) ?? [];
    const availableCurrencies = ISO_4217_CURRENCY_CODES.filter(code => !usedCodes.includes(code))

    return (
        <main>
            <article>
                <div className="flex w-full justify-between items-center">
                    <h2>Perfil</h2>
                    <Menu header={
                        <div className="p-1 h-fit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5" viewBox="0 0 24 24"><path fill="currentColor" d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0-4 0m0-6a2 2 0 1 0 4 0a2 2 0 0 0-4 0m0 12a2 2 0 1 0 4 0a2 2 0 0 0-4 0" /></svg>
                        </div>
                    }>
                        <p onClick={() => setConfirmDialog({ callback: handleSignOut, title: '¿Estás seguro que deseas cerrar sesión?', cancelButton: 'Cancelar', confirmButton: 'Cerrar sesión' })}>Cerrar sesión</p>
                        <p onClick={() => setConfirmDialog({ callback: handleDeleteAccount, title: '¿Estás seguro que deseas solicitar la eliminación de tu cuenta?', subtitle: 'Si te arrepientes puedes comunicarte con nosotros para cancelar la solicitud, dentro de los 10 días hábiles.', cancelButton: 'Cancelar', confirmButton: 'Confirmar' })}>Solicitar eliminación de cuenta</p>
                    </Menu>
                </div>
                <p>{client.email ?? ''}</p>
                <div className="bg-sf-gray-light p-2 rounded-lg">
                    <h3>Nombre</h3>
                    <input name='name' type='text' placeholder='Nombre' value={editableClient.name} onChange={handleInputChange} className='mt-2 field' />
                </div>

                <div className="bg-sf-gray-light pt-2 px-2 rounded-lg">
                    <h3>Monedas</h3>
                    <div className='flex flex-wrap mt-2'>
                        {editableClient.currencies?.map((currency, index) => <div key={index} className="flex items-center w-fit h-fit bg-sf-white p-2 mr-2 mb-2 rounded-lg">
                            <p className="break-normal hyphens-auto">{currency.code}</p>
                            {currency.sign && <p className="break-normal hyphens-auto ml-1">({currency.sign})</p>}
                            <div className="cursor-pointer ml-2 rounded-lg" onClick={() => removeCurrency(index)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24"><g fill="currentColor"><path d="M3 6.386c0-.484.345-.877.771-.877h2.665c.529-.016.996-.399 1.176-.965l.03-.1l.115-.391c.07-.24.131-.45.217-.637c.338-.739.964-1.252 1.687-1.383c.184-.033.378-.033.6-.033h3.478c.223 0 .417 0 .6.033c.723.131 1.35.644 1.687 1.383c.086.187.147.396.218.637l.114.391l.03.1c.18.566.74.95 1.27.965h2.57c.427 0 .772.393.772.877s-.345.877-.771.877H3.77c-.425 0-.77-.393-.77-.877Z" /><path fillRule="evenodd" d="M9.425 11.482c.413-.044.78.273.821.707l.5 5.263c.041.433-.26.82-.671.864c-.412.043-.78-.273-.821-.707l-.5-5.263c-.041-.434.26-.821.671-.864Zm5.15 0c.412.043.713.43.671.864l-.5 5.263c-.04.434-.408.75-.82.707c-.413-.044-.713-.43-.672-.864l.5-5.264c.041-.433.409-.75.82-.707Z" clipRule="evenodd" /><path d="M11.596 22h.808c2.783 0 4.174 0 5.08-.886c.904-.886.996-2.339 1.181-5.245l.267-4.188c.1-1.577.15-2.366-.303-2.865c-.454-.5-1.22-.5-2.753-.5H8.124c-1.533 0-2.3 0-2.753.5c-.454.5-.404 1.288-.303 2.865l.267 4.188c.185 2.906.277 4.36 1.182 5.245c.905.886 2.296.886 5.079.886Z" opacity=".5" /></g></svg>
                            </div>
                        </div>)}
                    </div>
                    <div className='w-full space-y-2'>
                        <Select placeholder='Selecciona una moneda *' name='currency' value={newCurrency.code} onChange={event => setNewCurrency(oldCurrency => ({ ...oldCurrency, code: (event.target as HTMLInputElement).value }))} options={availableCurrencies} />
                        <input name='currency' type='text' placeholder='Signo de la moneda (opcional)' value={newCurrency.sign} onChange={event => setNewCurrency(oldCurrency => ({ ...oldCurrency, sign: event.target.value }))} className='field' />
                    </div>
                    <div className="flex w-full justify-center items-center">
                        <button disabled={!newCurrency.code} className="button-primary" onClick={addCurrency}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                        </button>
                    </div>
                </div>

                {/*
                <div className="bg-sf-gray-light p-2 rounded-lg">
                    <h3>Otras opciones</h3>
                    <div className="w-fit flex items-center space-x-2 m-2 cursor-pointer" onClick={() => setEditableClient(old => ({ ...old, enable_plan: !old.enable_plan }))}>
                        <p>¿Habilitar características de planificación avanzadas?</p>
                        <div className="h-4 w-4 rounded-sm bg-sf-white flex justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className={`w-full text-sf-violet-dark ${editableClient.enable_plan ? '' : 'hidden'}`} viewBox="0 0 24 24"><path fill="currentColor" d="m10 13.6l5.9-5.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-6.6 6.6q-.3.3-.7.3t-.7-.3l-2.6-2.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l1.9 1.9Z" /></svg>
                        </div>
                    </div>
                </div>
                */}

                <button className="button-primary self-end" onClick={saveChanges}>Guardar</button>
            </article>
            <OperationDetails />
        </main>
    )
}
